<template>
  <div class="announ_details_warp">
    <div class="navBar">
      <div @click="$router.go(-1)" class="back">
        <svg-icon class="leftBtn" iconClass="back"></svg-icon>
      </div>
      <div class="title">公告详情</div>
      <div class="rightBtn"></div>
    </div>
    <div class="content">
      <div class="cover">
        <ImgDecypt :src="announData.cover" />
      </div>
      <div class="content_text">
        {{ announData.content }}
      </div>
      <div class="bottomTip">--本活动解释权归软妹社官方所有--</div>
    </div>
  </div>
</template>
<script>
import ImgDecypt from '@/components/ImgDecypt';
export default {
  components: {
    ImgDecypt,
  },
  data() {
    return {
      announData: {},
    };
  },
  created() {
    this.announData = this.$route.params.data;
  },
};
</script>
<style lang="scss" scoped>
.announ_details_warp {
  height: 100%;

  .navBar {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    background-color: #fff;
    box-sizing: border-box;
    border-bottom: 1px solid #e0e0e0;
    .back {
      width: 80px;
      display: flex;
      align-items: center;

      .leftBtn {
        width: 24px;
        height: 24px;
        font-size: 24px;
      }
    }

    .title {
      flex: 1;
      font-size: 18px;
      text-align: center;
      color: #000;
    }

    .rightBtn {
      width: 80px;
    }
  }

  .content {
    padding: 16px;
    height: calc(100% - 44px);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    .cover {
      height: 184px;
      border-radius: 8px;
      overflow: hidden;
    }

    .content_text {
      padding-top: 8px;
      font-size: 14px;
      line-height: 20px;
      padding-bottom: 60px;
      white-space: pre-wrap;
    }
    .bottomTip {
      height: 40px;
      width: 100%;
      text-align: center;
      line-height: 40px;
      background-color: #f6f6f6;
      font-size: 14px;
      color: rgb(71, 74, 94);
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}
</style>
